<template>
  <main class="lg:p-4">
    <sqr-page-header title="Branches" class="p-4" />
    <sqr-error-banner :error="loadError" />
    <div class="max-w-xl">
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <!-- <th>{{$t('insurer_id')}}</th> -->
            <th>{{ $t('branch_name') }}</th>
            <th>{{ $t('branch_abrev') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="branch in records" :key="branch.id">
            <td>{{ branch.name }}</td>
            <td>{{ branch.abrev }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'Branches',
  props: {
    bid: String
  },
  computed: {
    ...mapGetters('branches', ['records', 'loading', 'loadError'])
  },
  mounted() {
    this.sub({
      path: ['brokers', this.bid],
      orderBy: [['name', 'asc']]
    });
  },
  methods: {
    ...mapActions('branches', ['sub'])
  },
  metaInfo: {
    title: 'Branches'
  }
};
</script>
